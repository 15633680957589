// src/utils/blogUtils.js

import blogPosts from '../blog_posts/blogPosts.json';

export function getSortedPostsData() {
  return blogPosts.sort((a, b) => {
    if (a.date < b.date) {
      return 1;
    } else {
      return -1;
    }
  });
}

export function getPostData(id) {
  return blogPosts.find(post => post.id === id);
}

export function getAllPostIds() {
  return blogPosts.map(post => ({
    params: {
      id: post.id
    }
  }));
}
