import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { jStat } from 'jstat';
import { Card, CardHeader, CardContent, InfoIcon, Button, CollapsibleSection } from './ui';
import styles from '../styles/Utilities/Results.module.scss';
import { motion } from 'framer-motion';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';


const factorNames = {
  D: "Dominance",
  I: "Influence",
  S: "Steadiness",
  C: "Conscientiousness"
};

const factorDescriptions = {
  D: "Focuses on accomplishing results, seeing the big picture, and accepting challenges.",
  I: "Focuses on influencing or persuading others, being open, and building relationships.",
  S: "Focuses on cooperation, being sincere, and providing dependability.",
  C: "Focuses on quality, accuracy, expertise, and competency."
};

const factorDetailedDescriptions = {
  D: "People with high Dominance are direct, results-oriented, and assertive. They tend to be competitive and ambitious, often taking charge in group situations. They're motivated by new challenges and the freedom to make decisions.",
  I: "Those high in Influence are outgoing, enthusiastic, and optimistic. They excel at networking and building relationships. They're often creative problem-solvers who motivate and inspire others.",
  S: "Individuals with high Steadiness are patient, loyal, and good listeners. They prefer stable, predictable environments and excel at creating harmonious work atmospheres. They're reliable team players who provide consistent performance.",
  C: "People high in Conscientiousness are analytical, systematic, and precise. They focus on ensuring accuracy and maintaining high standards. They're excellent at critical thinking and approach problems in a logical, systematic manner."
};

const calculatePercentile = (score) => {
  const mean = 25;
  const sd = 5;
  return Math.round(jStat.normal.cdf(score, mean, sd) * 100);
};

const generateStyleDescription = (primaryStyle, secondaryStyle) => {
  const descriptions = {
    D: "You tend to be direct, results-oriented, and assertive.",
    I: "You tend to be outgoing, enthusiastic, and optimistic.",
    S: "You tend to be steady, patient, and accommodating.",
    C: "You tend to be analytical, reserved, and precise."
  };

  return `${descriptions[primaryStyle]} As your secondary style, ${descriptions[secondaryStyle].toLowerCase()}`;
};

const getStrengthsAndChallenges = (primaryStyle, secondaryStyle) => {
  const strengths = {
    D: ["Taking initiative", "Overcoming obstacles", "Making quick decisions"],
    I: ["Building relationships", "Generating enthusiasm", "Creative problem-solving"],
    S: ["Providing stability", "Being a good listener", "Promoting teamwork"],
    C: ["Ensuring accuracy", "Critical thinking", "Maintaining high standards"]
  };

  const challenges = {
    D: ["Being patient with others", "Considering others' feelings", "Paying attention to details"],
    I: ["Following through on tasks", "Time management", "Analyzing data objectively"],
    S: ["Adapting to rapid change", "Speaking up in groups", "Making quick decisions"],
    C: ["Delegating tasks", "Dealing with ambiguity", "Taking risks"]
  };

  return {
    strengths: [...strengths[primaryStyle], ...strengths[secondaryStyle]],
    challenges: [...challenges[primaryStyle], ...challenges[secondaryStyle]]
  };
};

const writeResponsesToJson = (scores) => {
  const jsonData = JSON.stringify(scores, null, 2);
  return new Blob([jsonData], { type: 'application/json' });
};

const uploadToS3 = async (file, identifier) => {
  console.log('Starting S3 upload...');
  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
  });

  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Key: `disc-results/${identifier}-${Date.now()}.json`,
    Body: file,
    ContentType: 'application/json',
  };


  try {
    const command = new PutObjectCommand(params);
    await s3Client.send(command);
    console.log('Successful');
  } catch (error) {
    console.error('Error');
  }
};
const DISCResultsComponent = ({ scores, userId }) => {
  const [sessionId, setSessionId] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');


  useEffect(() => {
    // Check if a sessionId already exists in localStorage
    let storedSessionId = localStorage.getItem('assessmentSessionId');
    
    if (!storedSessionId) {
      // If no sessionId exists, generate a new one and store it
      storedSessionId = uuidv4();
      localStorage.setItem('assessmentSessionId', storedSessionId);
    }
    
    setSessionId(storedSessionId);
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const effectiveId = userId || sessionId;
    console.log('Attempting upload with ID:', effectiveId);
    
    if (scores && effectiveId) {
      const jsonFile = writeResponsesToJson(scores);
      uploadToS3(jsonFile, effectiveId);
    }
  }, [scores, userId, sessionId]);

  if (!scores) {
    return <div className={styles.results__loading}>Generating your DISC results...</div>;
  }
  const { factors, primaryStyle, secondaryStyle } = scores;

  const percentileData = Object.entries(factors).map(([key, value]) => ({
    factor: factorNames[key],
    percentile: calculatePercentile(value)
  })).sort((a, b) => b.percentile - a.percentile);

  const { strengths, challenges } = getStrengthsAndChallenges(primaryStyle, secondaryStyle);

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    series: [{
      data: percentileData.map(item => item.percentile)
    }],
    xaxis: {
      categories: percentileData.map(item => item.factor),
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val + "%";
        }
      }
    },
    title: {
      text: 'DISC Profile Overview',
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  };
  const renderOverviewTab = () => (
    <>
      <Card className={styles.results__interpretation_item}>
        <CardHeader>Your DISC Style</CardHeader>
        <CardContent>
          <p className={styles.results__interpretation_text}>
            Your primary style is <strong>{factorNames[primaryStyle]}</strong>, 
            with <strong>{factorNames[secondaryStyle]}</strong> as your secondary style.
          </p>
          <p className={styles.results__interpretation_text}>
            {generateStyleDescription(primaryStyle, secondaryStyle)}
          </p>
        </CardContent>
      </Card>
      <Card className={styles.results__chart}>
        <CardHeader>DISC Profile Overview</CardHeader>
        <CardContent>
          <ReactApexChart options={barChartOptions} series={barChartOptions.series} type="bar" height={350} />
        </CardContent>
      </Card>
    </>
  );

  const renderDetailedResultsTab = () => (
    <>
      {percentileData.map(({ factor, percentile }) => (
        <motion.div
          key={factor}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card className={styles.results__interpretation_item}>
            <CardHeader>
              {factor}
            </CardHeader>
            <CardContent>
              <div className={styles.results__progress}>
                <div className={styles.results__progress_bar}>
                  <div 
                    className={styles.results__progress_fill} 
                    style={{ width: `${percentile}%` }}
                  />
                  <span className={styles.results__progress_label}>{percentile}%</span>
                </div>
              </div>
              <p className={styles.results__interpretation_text}>
                Your score for {factor} is in the <strong>{percentile}th percentile</strong>.
              </p>
              <CollapsibleSection 
                title={`Learn more about ${factor}`}
                className={styles.results__learn_more}
              >
                <p className={styles.results__interpretation_text}>{factorDetailedDescriptions[factor[0]]}</p>
              </CollapsibleSection>
            </CardContent>
          </Card>
        </motion.div>
      ))}
    </>
  );

  const renderApplicationsTab = () => (
    <>
      <Card className={styles.results__interpretation_item}>
        <CardHeader>Your Strengths and Potential Challenges</CardHeader>
        <CardContent>
          <div className={styles.results__strengths_challenges}>
            <div className={styles.results__strengths}>
              <h3 className={styles.results__subheader}>Strengths</h3>
              <ul className={styles.results__list}>
                {strengths.map((strength, index) => (
                  <li key={index} className={styles.results__list_item}>{strength}</li>
                ))}
              </ul>
            </div>
            <div className={styles.results__challenges}>
              <h3 className={styles.results__subheader}>Potential Challenges</h3>
              <ul className={styles.results__list}>
                {challenges.map((challenge, index) => (
                  <li key={index} className={styles.results__list_item}>{challenge}</li>
                ))}
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className={styles.results__interpretation_item}>
        <CardHeader>Understanding Your DISC Profile</CardHeader>
        <CardContent>
          <p className={styles.results__interpretation_text}>
            Your DISC profile provides insights into your behavioral style across four dimensions: 
            Dominance, Influence, Steadiness, and Conscientiousness. Each person has a unique blend 
            of these styles, with some being more prominent than others. Your primary and secondary 
            styles indicate your most natural behavioral tendencies, but remember that you can adapt 
            your behavior to different situations when needed.
          </p>
          <p className={styles.results__interpretation_text}>
            Understanding your DISC profile can help you:
          </p>
          <ul className={styles.results__list}>
            <li className={styles.results__list_item}>Improve communication with others</li>
            <li className={styles.results__list_item}>Enhance teamwork and collaboration</li>
            <li className={styles.results__list_item}>Identify potential areas for personal and professional growth</li>
            <li className={styles.results__list_item}>Make more informed career decisions</li>
            <li className={styles.results__list_item}>Develop strategies to manage stress and conflict</li>
          </ul>
          <p className={styles.results__interpretation_text}>
            Remember, there are no "good" or "bad" DISC profiles. Each style has its own strengths 
            and potential challenges. The key is to leverage your strengths while being aware of 
            areas where you might need to adapt or develop.
          </p>
        </CardContent>
      </Card>
    </>
  );

  return (
    <div className={styles.results}>
      <div className={styles.results__header}>
        <h1 className={styles.results__title}>Your DISC Profile</h1>
        <p className={styles.results__subtitle}>Discover your behavioral tendencies and strengths</p>
      </div>

      <div className={styles.results__tabs}>
        <Button 
          onClick={() => setActiveTab('overview')} 
          variant={activeTab === 'overview' ? 'primary' : 'secondary'}
        >
          Overview
        </Button>
        <Button 
          onClick={() => setActiveTab('detailed')} 
          variant={activeTab === 'detailed' ? 'primary' : 'secondary'}
        >
          Detailed Results
        </Button>
        <Button 
          onClick={() => setActiveTab('applications')} 
          variant={activeTab === 'applications' ? 'primary' : 'secondary'}
        >
          Applications
        </Button>
      </div>

      <div className={styles.results__content}>
        {activeTab === 'overview' && renderOverviewTab()}
        {activeTab === 'detailed' && renderDetailedResultsTab()}
        {activeTab === 'applications' && renderApplicationsTab()}
      </div>
    </div>
  );
};

export default DISCResultsComponent;