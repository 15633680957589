import React from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { getPostData } from './blogUtils';
import styles from '../styles/Utilities/BlogPost.module.scss';

// Import all images
const images = require.context('../images', true);
const imageMap = {};
images.keys().forEach((item) => {
  const key = item.replace('./', '');
  imageMap[key] = images(item);
});

const BlogPost = () => {
  const { id } = useParams();
  const post = getPostData(id);

  if (!post) {
    return (
      <div className={styles.notFound}>
        <div className={styles.notFoundContent}>
          <h1 className={styles.notFoundTitle}>Post not found</h1>
          <Link to="/blog" className={styles.backLink}>&larr; Back to Blog</Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.blogPostContainer}>
      <div className={styles.blogPostContent}>
        <Link to="/blog" className={styles.backLink}>&larr; Back to Blog</Link>
        <article className={styles.blogPost}>
          <header className={styles.blogPostHeader}>
            <h1 className={styles.blogPostTitle}>{post.title}</h1>
            <div className={styles.blogPostMeta}>
              <span>By {post.author}</span>
              <span>{post.date}</span>
            </div>
          </header>
          {post.imageUrl && (
            <img 
              src={imageMap[post.imageUrl]} 
              alt={post.title} 
              className={styles.blogPostImage} 
            />
          )}
          <div className={styles.blogPostBody}>
            <ReactMarkdown 
              components={{
                h1: ({node, ...props}) => <h1 className={styles.heading1} {...props} />,
                h2: ({node, ...props}) => <h2 className={styles.heading2} {...props} />,
                h3: ({node, ...props}) => <h3 className={styles.heading3} {...props} />,
                p: ({node, ...props}) => <p className={styles.paragraph} {...props} />,
                a: ({node, ...props}) => <a className={styles.link} {...props} />,
                ul: ({node, ...props}) => <ul className={styles.unorderedList} {...props} />,
                ol: ({node, ...props}) => <ol className={styles.orderedList} {...props} />,
                blockquote: ({node, ...props}) => <blockquote className={styles.blockquote} {...props} />,
              }}
            >
              {post.content}
            </ReactMarkdown>
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPost;
