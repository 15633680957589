import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Utilities/About.module.scss';

const About = () => {
  return (
   
   
    <div className={styles.about}>
    <div className={styles.container}>
      <h1 className={styles.title}>About Psychometrics.io: High-Quality Personality Tests, Free for Everyone.</h1>
  
      <section className={styles.section}>
        <p>Psychometrics.io is making professional-grade personality tests and psychometric tools accessible to all. No hidden costs. No compromise on quality. Empower yourself with insights that were once only available to a select few.</p>
      </section>

        <section className={styles.section}>
          <h2>Our Core Values</h2>
          <ul className={styles.valuesList}>
            <li><strong>Accessibility:</strong> All our tests are 100% free. No paywalls, no hidden fees.</li>
            <li><strong>Transparency:</strong> We're upfront about the scientific validity (or lack thereof) behind each test.</li>
            <li><strong>Education:</strong> We break down complex psychometric theories and research into easy-to-understand content.</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2>How We're Changing the Game</h2>
          <h3>Addressing Overpricing</h3>
          <p>Gone are the days of paying premium prices for personality insights. Our tests are completely free, allowing everyone to explore their psychological makeup without financial barriers.</p>
          
          <h3>Tackling Overhyped Claims</h3>
          <p>We cut through the noise of exaggerated marketing claims. Instead, we offer:</p>
          <ul>
            <li>Clear validity statements for each test</li>
            <li>An assessment resource hub to help you distinguish between scientifically robust tools and entertaining conversation starters</li>
            <li>In-depth blog posts explaining the theory and research behind psychometric testing</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2>Our Business Model</h2>
          <p>We keep the lights on through:</p>
          <ul>
            <li>Tasteful ads and carefully selected affiliate partnerships</li>
            <li>Using de-identified data for product development and research</li>
          </ul>
          <p>We believe in transparency, so we're upfront about these practices. While many paid services engage in similar practices, they often bury this information in lengthy terms of service or privacy policies.</p>
        </section>

        <section className={styles.section}>
          <h2>Why We Do It</h2>
          <p>Our passion lies in democratizing self-insight and promoting transparency in psychometrics. By making these tools freely available and providing honest information about their validity, we're empowering individuals to make informed decisions about their personal and professional development.</p>
        </section>

        <section className={styles.section}>
          <h2>Join Our Mission</h2>
          <p>At Psychometrics.io, we're redefining how personality tests should work—free, honest, and genuinely useful. Join us in this journey of self-discovery and psychological understanding.</p>
          <p>Together, let's unlock the potential of psychometrics for everyone.</p>
        </section>

        <div className={styles.cta}>
          <Link to="/assessments" className={styles.ctaButton}>Take a Test</Link>
        </div>
      </div>
    </div>
  );
};

export default About;
