import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Utilities/Privacy.module.scss';

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacy}>
      <div className={styles.container}>
        <h1 className={styles.title}>Privacy Policy</h1>
        <p className={styles.lastUpdated}>Last Updated: September 1, 2024</p>

        <section className={styles.section}>
          <h2>1. Introduction</h2>
          <p>Welcome to Psychometrics.io. We're committed to protecting your privacy and being transparent about how we use your data. This policy explains our data practices and your rights.</p>
        </section>

        <section className={styles.section}>
          <h2>2. Information We Collect</h2>
          <p>We collect information you provide directly to us, such as:</p>
          <ul>
            <li>Account information (e.g., name, email)</li>
            <li>Assessment responses</li>
            <li>Feedback and correspondence</li>
          </ul>
          <p>We also automatically collect certain information when you use our site, including:</p>
          <ul>
            <li>Usage data (e.g., pages visited, time spent on site)</li>
            <li>Device information (e.g., IP address, browser type)</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2>3. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Provide and improve our services</li>
            <li>Develop our data-driven products</li>
            <li>Generate personalized assessment results</li>
            <li>Communicate with you about our services</li>
            <li>Analyze usage patterns and improve user experience</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2>4. Data Sharing and Disclosure</h2>
          <p>We do not sell your personal information. We may share your information with:</p>
          <ul>
            <li>Service providers who help us operate our business</li>
            <li>Researchers (in anonymized, aggregate form only)</li>
            <li>Legal authorities when required by law</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2>5. Your Rights and Choices</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access, correct, or delete your personal information</li>
            <li>Object to or restrict certain data processing</li>
            <li>Data portability</li>
            <li>Withdraw consent at any time</li>
          </ul>
          <p>To exercise these rights, please contact us at <a href="mailto:privacy@psychometrics.io">privacy@psychometrics.io</a>.</p>
        </section>

        <section className={styles.section}>
          <h2>6. Data Security</h2>
          <p>We implement appropriate technical and organizational measures to protect your data. However, no method of transmission over the Internet is 100% secure, and we cannot guarantee absolute security.</p>
        </section>

        <section className={styles.section}>
          <h2>7. Cookies and Tracking</h2>
          <p>We use cookies and similar technologies to enhance your experience on our site. You can manage your cookie preferences through your browser settings.</p>
        </section>

        <section className={styles.section}>
          <h2>8. Changes to This Policy</h2>
          <p>We may update this policy from time to time. We will notify you of any significant changes by posting a notice on our website or sending you an email.</p>
        </section>

        <section className={styles.section}>
          <h2>9. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <p>Email: <a href="mailto:privacy@psychometrics.io">privacy@psychometrics.io</a></p>
        </section>

        <div className={styles.footer}>
          <Link to="/" className={styles.backLink}>Back to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;