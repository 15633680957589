// JungianTypesAssessment.js

import React, { useState } from 'react';
import AssessmentLoader from './AssessmentLoader';
import JungianTypesResultsComponent from './JungianTypeResults';
import styles from '../styles/Utilities/Assessment.module.scss';
import SEO from './SEO';

const JungianTypesAssessment = () => {
  const [showResults, setShowResults] = useState(false);
  const [scores, setScores] = useState(null);
  const [questions, setQuestions] = useState([]);

  const handleComplete = (completedResponses) => {
    const calculatedScores = calculateScores(completedResponses, questions);
    setScores(calculatedScores);
    setShowResults(true);
  };

  const calculateScores = (responses, questions) => {
    const dichotomies = { E: 0, I: 0, S: 0, N: 0, T: 0, F: 0, J: 0, P: 0 };

    questions.forEach((question, index) => {
      const response = responses[index];
      const { dichotomy, direction } = question;
      const score = direction === dichotomy[0] ? response : 8 - response;

      dichotomies[dichotomy[0]] += score;
      dichotomies[dichotomy[1]] += (8 - score);
    });

    const type = Object.keys(dichotomies)
      .filter(key => ['E', 'S', 'T', 'J'].includes(key))
      .map(key => dichotomies[key] >= dichotomies[key === 'E' ? 'I' : key === 'S' ? 'N' : key === 'T' ? 'F' : 'P'] ? key : key === 'E' ? 'I' : key === 'S' ? 'N' : key === 'T' ? 'F' : 'P')
      .join('');

    return { dichotomies, type };
  };

  const handleQuestionsLoaded = (loadedQuestions) => {
    setQuestions(loadedQuestions);
  };

  return (
    <div className={styles.assessment}>
                 <SEO 
        title="Jungian Type Assessment | Psychometrics.io"
        description="Discover your true self with scientifically backed personality assessments for personal and professional growth."
        keywords="psychometrics, personality tests, Big Five, DISC, Jungian Type, Free Assessment, 16 types"
        ogImage="https://psychometrics.io/static/media/psychometrics-logo.png"
      />
      <h1 className={styles.assessmentTitle}>Jungian Types Assessment</h1>
      {!showResults ? (
        <AssessmentLoader
          assessmentId="jungian-type"
          onComplete={handleComplete}
          onQuestionsLoaded={handleQuestionsLoaded}
        />
      ) : (
        <JungianTypesResultsComponent scores={scores} />
      )}
    </div>
  );
};

export default JungianTypesAssessment;
