import React, { useEffect, useRef, useState } from 'react';

const AdTag = ({ adScript, style }) => {
  const adContainerRef = useRef(null);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient && adContainerRef.current) {
      const script = document.createElement('script');
      script.innerHTML = adScript;
      adContainerRef.current.appendChild(script);
    }

    return () => {
      if (adContainerRef.current) {
        adContainerRef.current.innerHTML = '';
      }
    };
  }, [adScript, isClient]);

  const defaultStyle = {
    position: 'relative',
    zIndex: 1,
    margin: '20px 0',
    minHeight: '250px'
  };

  // Render a placeholder during SSR
  if (!isClient) {
    return <div style={{ ...defaultStyle, ...style }} data-testid="ad-placeholder" />;
  }

  return <div ref={adContainerRef} style={{ ...defaultStyle, ...style }} />;
};

export default AdTag;