import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as TwitterIcon } from '../images/twitter.svg';
import { ReactComponent as InstagramIcon } from '../images/instagram.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__section">
            <h3 className="footer__title">Legal</h3>
            <ul className="footer__list">
              <li><Link to="/privacy">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="footer__section">
            <h3 className="footer__title">Connect With Us</h3>
            <div className="footer__social">
              <a href="https://x.com/psychometricsio" target="_blank" rel="noopener noreferrer" aria-label="X.com" className="footer__social-link">
                <TwitterIcon />
              </a>
              <a href="https://instagram.com/psychographics.io" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="footer__social-link">
                <InstagramIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <p>&copy; {new Date().getFullYear()} Psychographics.io. All rights reserved.</p>
          <p>Icons made from <a href="https://www.onlinewebfonts.com/icon">svg icons</a> is licensed by CC BY 4.0</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
