import React, { useState } from 'react';
import AssessmentLoader from './AssessmentLoader';
import ResultsComponent from './BigFiveResults';
import styles from '../styles/Utilities/Assessment.module.scss';
import SEO from './SEO';
import AdTag from './AdTag';
const BigFiveAssessment = () => {
  const [showResults, setShowResults] = useState(false);
  const [scores, setScores] = useState(null);
  const [questions, setQuestions] = useState([]);

  const handleComplete = (completedResponses) => {
    const calculatedScores = calculateScores(completedResponses, questions);
    setScores(calculatedScores);
    setShowResults(true);
  };

  const calculateScores = (responses, questions) => {
    const traits = ['O', 'C', 'E', 'A', 'N'];
    const scores = {};

    traits.forEach((trait) => {
      const traitQuestions = questions.filter((q) => q.factor === trait);
      const traitResponses = traitQuestions.map((q) => {
        const response = responses[questions.indexOf(q)];
        return q.reverse ? 8 - response : response; // 8 - response for reverse-coded items (7-point scale)
      });
      scores[trait] = Math.round(traitResponses.reduce((sum, score) => sum + score, 0));
    });

    return scores;
  };

  const handleQuestionsLoaded = (loadedQuestions) => {
    setQuestions(loadedQuestions);
  };

  const adScriptContent = `
    (function(lhwn){
      var d = document,
          s = d.createElement('script'),
          l = d.scripts[d.scripts.length - 1];
      s.settings = lhwn || {};
      s.src = "//infantilecombination.com/akW.5/w/YBWQdcloQz2E9KklZuTs9q6fbU2V5ul/SaWIQ/9YNwTzUW1MMyTmct5nN_C/0Q1TNgTEU/xlNTzkkI1l";
      s.async = true;
      s.referrerPolicy = 'no-referrer-when-downgrade';
      l.parentNode.insertBefore(s, l);
    })({})
  `;
  return (
    <div className={styles.assessment}>
            <AdTag adScript={adScriptContent} />

                            <SEO 
        title="Big Five Personality Assessment | Psychometrics.io"
        description="Discover your true self with scientifically backed personality assessments for personal and professional growth."
        keywords="psychometrics, personality tests, Big Five, DISC, Jungian Type, Free Assessment"
        ogImage="https://psychometrics.io/static/media/psychometrics-logo.png"
      />
      {!showResults ? (
        <AssessmentLoader
          assessmentId="big-five"
          onComplete={handleComplete}
          onQuestionsLoaded={handleQuestionsLoaded}
        />
      ) : (
        <ResultsComponent scores={scores} />
      )}
    </div>
  );
};

export default BigFiveAssessment;
