import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import BigFiveAssessment from './components/BigFiveAssessment';
import JungianTypeAssessment from './components/JungianTypeAssessment';
import DISCAssessment from './components/DISCAssessment';
import About from './components/About';
import PrivacyPolicy from './components/PrivacyPolicy';
import Assessments from './components/Assessments';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/about" element={<About />} />
    <Route path="/privacy" element={<PrivacyPolicy />} />
    <Route path="/assessments" element={<Assessments />} />
    <Route path="/blog/:id" element={<BlogPost />} />
    <Route path="/big-five" element={<BigFiveAssessment />} />
    <Route path="/jungian-type" element={<JungianTypeAssessment />} />
    <Route path="/disc" element={<DISCAssessment />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

const App = () => {
  const RouterWrapper = typeof window === 'undefined' ? React.Fragment : Router;

  return (
    <RouterWrapper>
      <Helmet titleTemplate="%s | Psychometrics.io" defaultTitle="Psychometrics.io">
        <meta name="description" content=" Free scientifically backed personality assessments, big five, Jung, Myers-Briggs, DISC, Evidence-Based, Tests " />
      </Helmet>
      <div className="app">
        <Header />
        <main>
          <AppRoutes />
        </main>
        <Footer />
      </div>
    </RouterWrapper>
  );
};

export default App;