import React from 'react';
import { Link } from 'react-router-dom';
import { getSortedPostsData } from './blogUtils';
import styles from '../styles/Utilities/Blog.module.scss';
import SEO from './SEO';

// Import all images
const images = require.context('../images', true);
const imageMap = {};
images.keys().forEach((item) => {
  const key = item.replace('./', '');
  imageMap[key] = images(item);
});

const FeaturedPost = ({ post }) => (
  <div className={styles.featuredPost}>
    <div className={styles.featuredPostInner}>
      <img src={imageMap[post.imageUrl]} alt={post.title} className={styles.featuredImage} />
      <div className={styles.featuredContent}>
        <h2 className={styles.featuredTitle}>{post.title}</h2>
        <p className={styles.featuredExcerpt}>{post.excerpt}</p>
        <p className={styles.featuredMeta}>By {post.author} on {post.date}</p>
        <Link to={`/blog/${post.id}`} className="btn btn--secondary">Read More</Link>
      </div>
    </div>
  </div>
);

const BlogPostCard = ({ post }) => (
  <div className={styles.blogCard}>
    <img src={imageMap[post.imageUrl]} alt={post.title} className={styles.blogCardImage} />
    <div className={styles.blogCardContent}>
      <h3 className={styles.blogCardTitle}>{post.title}</h3>
      <p className={styles.blogCardExcerpt}>{post.excerpt}</p>
      <p className={styles.blogCardMeta}>By {post.author} on {post.date}</p>
      <Link to={`/blog/${post.id}`} className={styles.blogCardLink}>Read More &rarr;</Link>
    </div>
  </div>
);

const Blog = () => {
  const blogPosts = getSortedPostsData();
  const featuredPost = blogPosts.find(post => post.featured);
  const regularPosts = blogPosts.filter(post => !post.featured);

  return (
    <div className={styles.blogContainer}>
            <SEO 
        title="Blog | Psychometrics.io"
        description="Discover your true self with scientifically backed personality assessments for personal and professional growth."
        keywords="psychometrics, personality tests, Big Five, DISC, Jungian Type"
        ogImage="https://psychometrics.io/static/media/psychometrics-logo.png"
      />
      <div className={styles.blogContent}>
        <h1 className={styles.blogTitle}>Psychometrics Insights</h1>
        {featuredPost && <FeaturedPost post={featuredPost} />}
        <div className={styles.blogGrid}>
          {regularPosts.map(post => (
            <BlogPostCard key={post.id} post={post} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
