import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { getSortedPostsData } from './blogUtils';
import SEO from './SEO';
import AdTag from './AdTag'
const assessmentsData = [
  {
    title: "Big Five Personality",
    description: "This assessment has the strongest research support. Find out your trait scores",
    link: "/big-five",
    image: "big-five.jpg"
  },
    {
    title: "DISC Assessment",
    description: "Based on a popular leadership assessment. Find out your leadership style.",
    link: "/disc",
    image: "briefcase.jpg"
  },
  {
    title: "Jungian Type",
    description: "Based om the popular Myers-Briggs personality model (added by popular demand).",
    link: "/Jungian-Type",
    image: "jungian.jpg"
  }
];
const adScriptContent = `
(function(lhwn){
  var d = document,
      s = d.createElement('script'),
      l = d.scripts[d.scripts.length - 1];
  s.settings = lhwn || {};
  s.src = "//infantilecombination.com/b.XkVPsHd/GXlX0wYmWudHiOYeWN5/u/ZbX/IA/CeTmI9RuSZeUSlmk/PyTKUQ1kNLDdYPwXNUTFYmt/N/TEU/0XNXj/AY1_NowH";
  s.async = true;
  s.referrerPolicy = 'no-referrer-when-downgrade';
  l.parentNode.insertBefore(s, l);
})({})
`;
const Home = () => {
  const allPosts = getSortedPostsData();
  const featuredPost = allPosts.find(post => post.featured);

  return (
          <div className="home">
      <SEO 
        title="Home | Psychometrics.io"
        description="Discover your true self with scientifically backed personality assessments for personal and professional growth."
        keywords="psychometrics, personality tests, Big Five, DISC, Jungian Type"
        ogImage="https://psychometrics.io/static/media/psychometrics-logo.png"
      />
      {/* Hero Section */}
      <section className="hero" style={{backgroundImage: `url(${require('../images/group-hero.jpg')})`}}>
        <div className="hero__content">
          <h1 className="hero__title">Discover Your True Self</h1>
          <p className="hero__subtitle">Scientifically backed personality assessments for personal and professional growth</p>
          <Link to="/assessments" className="btn btn--primary hero__cta">
            Start Your Journey
          </Link>
        </div>
      </section>

      {/* Our Assessments */}
      <section className="section section--alternate">
        <div className="container">
          <h2 className="section__title">Featured Assessments</h2>
          <div className="row">
            {assessmentsData.map((assessment, index) => (
              <div key={index} className="col col-md-4">
                <div className="card">
                  <div className="card__header">
                    <img src={require(`../images/${assessment.image}`)} alt={assessment.title} className="card__icon" />
                  </div>
                  <div className="card__body">
                    <h3 className="card__title">{assessment.title}</h3>
                    <p className="card__text">{assessment.description}</p>
                    <Link to={assessment.link} className="card__link">
                      Learn More <ArrowRight size={16} />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
     {/* Your other content */}
    {/* Featured Blog Post */}
{featuredPost && (
  <section className="section sectionBottomPadding">
    <div className="container">
      <h2 className="section__title">Featured Article</h2>
      <div className="featured-post">
        <div className="featured-post__image">
          <img src={require(`../images/${featuredPost.imageUrl}`)} alt={featuredPost.title} />
        </div>
        <div className="featured-post__content">
          <h3 className="featured-post__title">{featuredPost.title}</h3>
          <p className="featured-post__excerpt">{featuredPost.excerpt}</p>
          <p className="featured-post__meta">By {featuredPost.author} on {featuredPost.date}</p>
          <Link to={`/blog/${featuredPost.id}`} className="btn btn--secondary">
            Read More <ArrowRight size={16} />
          </Link>
        </div>
      </div>
    </div>
  </section>
)}
    </div>
  );
};

export default Home;
