import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, CardContent, InfoIcon, Button } from './ui';
import styles from '../styles/Utilities/Results.module.scss';
import { motion } from 'framer-motion';
import jstat from 'jstat';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';

const traitNames = {
  O: "Openness to Experience",
  C: "Conscientiousness",
  E: "Extraversion",
  A: "Agreeableness",
  N: "Neuroticism"
};

const traitDescriptions = {
  O: "Openness reflects curiosity, creativity, and openness to new experiences. It encompasses intellectual curiosity, aesthetic sensitivity, vivid imagination, behavioral flexibility, and unconventional attitudes.",
  C: "Conscientiousness indicates organization, dependability, and achievement-orientation. It involves being careful, thorough, responsible, organized, hardworking, persevering, and self-disciplined.",
  E: "Extraversion captures sociability, assertiveness, and emotional expressiveness. It includes traits like gregariousness, assertiveness, activity level, excitement-seeking, positive emotions, and warmth.",
  A: "Agreeableness represents trust, altruism, and prosocial behaviors. It involves being kind, sympathetic, cooperative, warm, and considerate. High agreeableness is often associated with empathy and harmonious interpersonal relationships.",
  N: "Neuroticism refers to emotional stability and the tendency to experience negative emotions. It includes traits like anxiety, angry hostility, depression, self-consciousness, impulsiveness, and vulnerability to stress."
};

const traitEvidenceBasedInsights = {
  O: "Research has shown that individuals high in openness tend to be more creative and innovative. They are often drawn to artistic and scientific pursuits, and tend to perform well in environments that require adaptability and novel thinking (DeYoung et al., 2014).",
  C: "Studies have consistently linked high conscientiousness to better job performance across various occupations. Conscientious individuals tend to set and pursue goals effectively, leading to greater academic and professional success (Barrick & Mount, 1991).",
  E: "Extraverts tend to experience more positive emotions and have larger social networks. Research suggests that extraverted individuals often excel in leadership roles and jobs requiring frequent social interaction (Judge et al., 2002).",
  A: "High agreeableness is associated with better interpersonal relationships and teamwork. Agreeable individuals tend to be more empathetic and are often skilled at resolving conflicts (Graziano & Tobin, 2009).",
  N: "While high neuroticism is associated with increased vulnerability to stress and negative emotions, it's also linked to heightened self-awareness. Some studies suggest that moderate levels of neuroticism can lead to increased vigilance and attention to detail in certain situations (Nettle, 2006)."
};

const calculatePercentile = (score) => {
  const mean = 50;
  const sd = 10;
  return Math.round(jstat.normal.cdf(score, mean, sd) * 100);
};

const getScoreCategory = (score) => {
  if (score < 45) return 'low';
  if (score < 55) return 'average';
  return 'high';
};

const uploadToS3 = async (file, identifier) => {
  console.log('Starting S3 upload...');
  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
  });


  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Key: `big-five-results/${identifier}-${Date.now()}.json`,
    Body: file,
    ContentType: 'application/json',
  };


  try {
    const command = new PutObjectCommand(params);
    await s3Client.send(command);
    console.log('Successful');
  } catch (error) {
    console.error('Error');
  }
};

const ResultsComponent = ({ scores, userId }) => {
  const [sessionId, setSessionId] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState('work');
  const [descriptionData, setDescriptionData] = useState({});
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    // Check if a sessionId already exists in localStorage
    let storedSessionId = localStorage.getItem('assessmentSessionId');
    
    if (!storedSessionId) {
      // If no sessionId exists, generate a new one and store it
      storedSessionId = uuidv4();
      localStorage.setItem('assessmentSessionId', storedSessionId);
    }
    
    setSessionId(storedSessionId);
  }, []);

  useEffect(() => {
    const effectiveId = userId || sessionId;
    console.log('Attempting upload with ID:', effectiveId);
    
    if (scores && effectiveId) {
      const jsonFile = new Blob([JSON.stringify(scores)], { type: 'application/json' });
      uploadToS3(jsonFile, effectiveId);
    }
  }, [scores, userId, sessionId]);


  useEffect(() => {
    const fetchDescriptionData = async () => {
      // This is sample data. In a real application, this would be fetched from an API or imported from a file.
      const sampleData = {
        work: {
          O: {
            low: "In work contexts, individuals low in openness may prefer routine tasks and established methods. They might excel in roles that require adherence to traditional approaches.",
            average: "With average openness, you likely balance well between tried-and-true methods and new approaches at work. You can adapt to changes when necessary but also appreciate stable routines.",
            high: "High openness in work settings often translates to a love for innovation and creative problem-solving. You may thrive in roles that require thinking outside the box and adapting to new situations."
          },
          C: {
            low: "Lower conscientiousness at work might mean you prefer flexible schedules and spontaneous approaches to tasks. You might excel in dynamic environments that require quick shifts in focus.",
            average: "With average conscientiousness, you likely balance well between structure and flexibility at work. You can follow schedules and plans but also adapt when needed.",
            high: "High conscientiousness often translates to strong work ethic, attention to detail, and reliability. You likely excel in roles that require careful planning and consistent follow-through."
          },
          E: {
            low: "In work settings, lower extraversion might mean you prefer independent work and quiet environments. You might excel in roles that require deep focus and minimal social interaction.",
            average: "With average extraversion, you likely balance well between collaborative and independent work. You can engage in team projects but also appreciate time for solo tasks.",
            high: "High extraversion at work often means you thrive in social, collaborative environments. You might excel in roles that involve frequent interaction with others, such as sales or management."
          },
          A: {
            low: "Lower agreeableness in work contexts might mean you're comfortable with competition and making tough decisions. You might excel in roles that require negotiation or critical analysis.",
            average: "With average agreeableness, you likely balance well between cooperation and assertiveness at work. You can work well in teams but also stand your ground when needed.",
            high: "High agreeableness at work often translates to strong teamwork skills and a harmonious presence. You might excel in roles that require collaboration, customer service, or conflict resolution."
          },
          N: {
            low: "Lower neuroticism in work settings often means you handle stress well and remain calm under pressure. You might excel in high-stress roles or leadership positions.",
            average: "With average neuroticism, you likely balance well between staying alert to potential issues and maintaining calm. You can handle stress but also remain vigilant to important concerns.",
            high: "Higher neuroticism at work might mean you're very alert to potential problems and details others might miss. You might excel in roles that require careful risk assessment or quality control."
          }
        },
        relationships: {
          O: {
            low: "In relationships, lower openness might mean you value tradition and consistency. You might prefer stable, predictable relationships and activities you're familiar with.",
            average: "With average openness in relationships, you likely balance between routine and novelty. You can enjoy both familiar activities and occasional new experiences with your partner.",
            high: "High openness in relationships often means you enjoy exploring new experiences with your partner. You might thrive in relationships that offer intellectual stimulation and variety."
          },
          C: {
            low: "Lower conscientiousness in relationships might mean you prefer spontaneity and flexibility. You might enjoy partners who are easy-going and adaptable.",
            average: "With average conscientiousness in relationships, you likely balance between planning and spontaneity. You can be reliable while also enjoying occasional impulsivity.",
            high: "High conscientiousness in relationships often means you're reliable and organized. You might prefer partners who are equally responsible and value planning."
          },
          E: {
            low: "In relationships, lower extraversion might mean you value deep, one-on-one connections and quiet time together. You might prefer intimate gatherings to large social events.",
            average: "With average extraversion in relationships, you likely balance between social time and alone time. You can enjoy both quiet nights in and social outings with your partner.",
            high: "High extraversion in relationships often means you enjoy socializing as a couple. You might thrive with a partner who enjoys an active social life and frequent interactions with others."
          },
          A: {
            low: "Lower agreeableness in relationships might mean you're comfortable with debate and asserting your needs. You might prefer partners who can engage in direct, frank communication.",
            average: "With average agreeableness in relationships, you likely balance between assertiveness and compromise. You can cooperate well but also express your needs clearly.",
            high: "High agreeableness in relationships often means you're empathetic and eager to please. You might excel at creating harmonious relationships and resolving conflicts peacefully."
          },
          N: {
            low: "Lower neuroticism in relationships often means you're emotionally stable and calm. You might handle relationship stress well and not be easily upset by small issues.",
            average: "With average neuroticism in relationships, you likely balance between emotional responsiveness and stability. You can be in touch with your emotions without being overwhelmed by them.",
            high: "Higher neuroticism in relationships might mean you're very emotionally responsive and attuned to your partner's feelings. You might be quick to notice and address emotional issues in the relationship."
          }
        },
        growth: {
          O: {
            low: "For personal growth, those lower in openness might benefit from gradually exposing themselves to new experiences and ideas. Try incorporating small changes into your routine to broaden your comfort zone.",
            average: "With average openness, your path to growth might involve deepening your interests in certain areas while also remaining open to new experiences. Balance depth and breadth in your personal development.",
            high: "High openness individuals might focus their growth on channeling their curiosity and creativity effectively. Consider setting specific goals for your diverse interests to avoid becoming scattered."
          },
          C: {
            low: "For personal growth, those lower in conscientiousness might benefit from developing organizational skills and setting small, achievable goals. Start with short-term plans and gradually work towards longer-term objectives.",
            average: "With average conscientiousness, your growth path might involve refining your ability to balance structure and flexibility. Work on improving your planning skills while maintaining your adaptability.",
            high: "Highly conscientious individuals might focus their growth on learning to be more flexible and spontaneous. While planning is a strength, practicing adaptability can broaden your capabilities."
          },
          E: {
            low: "For personal growth, those lower in extraversion might benefit from gradually increasing their comfort with social interactions. Set small goals for social engagement while respecting your need for solitude.",
            average: "With average extraversion, your growth path might involve learning to leverage both your social skills and your ability to work independently. Seek opportunities that allow you to use both skill sets.",
            high: "Highly extraverted individuals might focus their growth on developing deeper, one-on-one relationships and improving their listening skills. While socializing is a strength, cultivating depth can be rewarding."
          },
          A: {
            low: "For personal growth, those lower in agreeableness might benefit from practicing empathy and cooperative skills. Look for opportunities to understand others' perspectives and find win-win solutions.",
            average: "With average agreeableness, your growth path might involve refining your ability to balance assertiveness with cooperation. Work on expressing your needs clearly while maintaining harmony.",
            high: "Highly agreeable individuals might focus their growth on developing assertiveness and setting healthy boundaries. While cooperation is a strength, learning to prioritize your own needs at times is important."
          },
          N: {
            low: "For personal growth, those lower in neuroticism might benefit from developing greater emotional awareness. While emotional stability is a strength, tuning into subtle emotional cues can enhance relationships and self-understanding.",
            average: "With average neuroticism, your growth path might involve refining your emotional regulation skills. Work on understanding your emotional triggers and developing coping strategies for stress.",
            high: "Highly neurotic individuals might focus their growth on developing resilience and stress management techniques. Consider practices like mindfulness or cognitive-behavioral strategies to manage anxiety and negative emotions."
          }
        }
      };

      setDescriptionData(sampleData);
    };

    fetchDescriptionData();
  }, []);

  if (!scores) {
    return <div className={styles.results__loading}>Analyzing your unique personality profile...</div>;
  }

  const radarChartOptions = {
    chart: {
      type: 'radar',
      toolbar: {
        show: false
      }
    },
    series: [{
      name: 'Score',
      data: Object.values(scores)
    }],
    labels: Object.values(traitNames),
    plotOptions: {
      radar: {
        polygons: {
          strokeColors: '#e9e9e9',
          fill: {
            colors: ['#f8f8f8', '#fff']
          }
        }
      }
    },
    colors: ['#0077BE'],
    markers: {
      size: 4,
      colors: ['#fff'],
      strokeColor: '#0077BE',
      strokeWidth: 2,
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val;
        }
      }
    },
    yaxis: {
      tickAmount: 10,
      min: 0,
      max: 100
    }
  };

  const barChartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%',
        distributed: true,
        dataLabels: {
          position: 'bottom'
        },
      }
    },
    colors: ['#0077BE', '#20B2AA', '#1A2A4A', '#90AFC5', '#2C3E50'],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      offsetX: 0,
      dropShadow: {
        enabled: true
      }
    },
    series: [{
      data: Object.values(scores)
    }],
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: Object.values(traitNames),
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return '';
          }
        }
      }
    }
  };

  const renderOverviewTab = () => (
    <>
      <Card className={styles.results__chart}>
        <CardHeader>Personality Overview</CardHeader>
        <CardContent>
          <ReactApexChart options={radarChartOptions} series={radarChartOptions.series} type="radar" height={450} />
        </CardContent>
      </Card>
      <Card className={styles.results__chart}>
        <CardHeader>Trait Comparison</CardHeader>
        <CardContent>
          <ReactApexChart options={barChartOptions} series={barChartOptions.series} type="bar" height={350} />
        </CardContent>
      </Card>
    </>
  );

  const renderDetailedResultsTab = () => (
    <>
      {Object.entries(scores).map(([trait, score]) => (
        <motion.div
          key={trait}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card className={styles.results__interpretation_item}>
            <CardHeader>
              {traitNames[trait]}
            </CardHeader>
            <CardContent>
              <div className={styles.results__progress}>
                <div className={styles.results__progress_bar}>
                  <div 
                    className={styles.results__progress_fill} 
                    style={{ width: `${score}%` }}
                  />
                  <span className={styles.results__progress_label}>{score}</span>
                </div>
                <div className={styles.results__progress_labels}>
                  <span>Low</span>
                  <span>Average</span>
                  <span>High</span>
                </div>
              </div>
              <h4>Your {traitNames[trait]}</h4>
              <p className={styles.results__interpretation_text}>
                {descriptionData[selectedDomain] && descriptionData[selectedDomain][trait] && 
                descriptionData[selectedDomain][trait][getScoreCategory(score)]}
              </p>
              <h4>Evidence-Based Insights</h4>
              <p className={styles.results__interpretation_text}>
                {traitEvidenceBasedInsights[trait]}
              </p>
            </CardContent>
          </Card>
        </motion.div>
      ))}
    </>
  );

  const renderPracticalApplicationsTab = () => (
    <Card className={styles.results__interpretation_item}>
      <CardHeader>Your Personality in Context</CardHeader>
      <CardContent>
        <div className={styles.results__domain_buttons}>
          {['work', 'relationships', 'growth'].map((domain) => (
            <Button 
              key={domain}
              onClick={() => setSelectedDomain(domain)}
              variant={selectedDomain === domain ? 'primary' : 'secondary'}
            >
              {domain.charAt(0).toUpperCase() + domain.slice(1)}
            </Button>
          ))}
        </div>
        {Object.entries(scores).map(([trait, score]) => (
          <div key={trait} className={styles.results__domain_item}>
            <h4>{traitNames[trait]}</h4>
            <p className={styles.results__interpretation_text}>
              {descriptionData[selectedDomain] && descriptionData[selectedDomain][trait] && 
              descriptionData[selectedDomain][trait][getScoreCategory(score)]}
            </p>
          </div>
        ))}
      </CardContent>
    </Card>
  );

  return (
    <div className={styles.results}>
      <div className={styles.results__header}>
        <h1 className={styles.results__title}>Your Unique Personality Profile</h1>
      </div>

      <div className={styles.results__tabs}>
        <Button 
          onClick={() => setActiveTab('overview')} 
          variant={activeTab === 'overview' ? 'primary' : 'secondary'}
        >
          Overview
        </Button>
        <Button 
          onClick={() => setActiveTab('detailed')} 
          variant={activeTab === 'detailed' ? 'primary' : 'secondary'}
        >
          Detailed Results
        </Button>
        <Button 
          onClick={() => setActiveTab('applications')} 
          variant={activeTab === 'applications' ? 'primary' : 'secondary'}
        >
          Practical Applications
        </Button>
      </div>

      <div className={styles.results__content}>
        {activeTab === 'overview' && renderOverviewTab()}
        {activeTab === 'detailed' && renderDetailedResultsTab()}
        {activeTab === 'applications' && renderPracticalApplicationsTab()}
      </div>
    </div>
  );
};

export default ResultsComponent;