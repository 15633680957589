import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Utilities/AssessmentsPage.module.scss';

const assessments = [
  {
    id: 'big-five',
    title: 'Big Five Personality Test',
    description: 'Discover your scores in the five major dimensions of personality: Openness, Conscientiousness, Extraversion, Agreeableness, and Neuroticism.',
    validityStatement: 'The Big Five model is widely accepted in the scientific community and has strong empirical support.',
  },
  {
    id: 'jungian-type',
    title: 'Jungian Type Assessment',
    description: "Explore your personality type based on Carl Jung's theory of psychological types, identifying preferences across four dichotomies.",
    validityStatement: 'Based on the popular Myers-Briggs theory, this assessment is widely used for self-reflection and personal development. Though extremely popular, the evidence for the valdity of the Myers-Briggs model is weak.',
  },
  {
    id: 'disc',
    title: 'DISC Assessment',
    description: 'Understand your behavioral style across four dimensions: Dominance, Influence, Steadiness, and Conscientiousness.',
    validityStatement: 'The DISC model is widely used in professional settings but has limited scientific validation compared to other personality models. Limited research exists on this measure, so its best to treat this as questionable.',
  },
];

const AssessmentCard = ({ assessment }) => (
  <div className={styles.assessmentCard}>
    <h2 className={styles.assessmentTitle}>{assessment.title}</h2>
    <p className={styles.assessmentDescription}>{assessment.description}</p>
    <p className={styles.validityStatement}><strong>Validity:</strong> {assessment.validityStatement}</p>
    <Link to={`/${assessment.id}`} className={styles.takeTestButton}>
      Take the Test
    </Link>
  </div>
);

const Assessments = () => {
  return (
    <div className={styles.assessments}>
      <div className={styles.container}>
        <h1 className={styles.pageTitle}>Our Assessments</h1>
        <p className={styles.pageDescription}>
          Explore our range of free, scientifically-informed personality assessments. 
          Remember, while these tests can provide valuable insights, they are not diagnostic tools. 
          Use them for self-reflection and personal growth.
        </p>
        <div className={styles.assessmentGrid}>
          {assessments.map((assessment) => (
            <AssessmentCard key={assessment.id} assessment={assessment} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Assessments;
