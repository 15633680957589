import React, { useState } from 'react';
import { ChevronDown, ChevronUp, HelpCircle } from 'lucide-react';

export const Button = ({ children, onClick, className, variant, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-6 py-3 rounded-full transition-all duration-300 font-bold text-base shadow-md transform hover:shadow-lg ${
      variant === 'outline'
        ? 'border-2 border-cerulean-blue text-cerulean-blue hover:bg-soft-gray'
        : disabled
          ? 'bg-soft-gray text-charcoal cursor-not-allowed'
          : 'bg-cerulean-blue text-white hover:bg-accent-teal'
    } ${className}`}
  >
    {children}
  </button>
);

export const Card = ({ children, className }) => (
  <div className={`bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:shadow-xl ${className}`}>
    {children}
  </div>
);

export const CardHeader = ({ children, className }) => (
  <div className={`px-6 py-4 bg-deep-navy text-white ${className}`}>
    <h3 className="text-xl font-bold">{children}</h3>
  </div>
);

export const CardContent = ({ children, className }) => (
  <div className={`px-6 py-4 ${className}`}>
    {children}
  </div>
);

export const ProgressBar = ({ value, max, className }) => (
  <div className={`w-full bg-soft-gray rounded-full h-2 ${className}`}>
    <div
      className="bg-cerulean-blue h-2 rounded-full transition-all duration-300 ease-out"
      style={{ width: `${(value / max) * 100}%` }}
    >
    </div>
  </div>
);

export const QuestionCard = ({ question, options, onSelect, selectedIndex }) => (
  <Card className="my-6">
    <CardHeader>{question}</CardHeader>
    <CardContent>
      <div className="space-y-3">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => onSelect(index)}
            className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-200 hover:bg-soft-gray focus:outline-none focus:ring-2 focus:ring-cerulean-blue ${
              selectedIndex === index ? 'bg-cerulean-blue text-white' : 'text-charcoal'
            }`}
          >
            {option}
          </button>
        ))}
      </div>
    </CardContent>
  </Card>
);

export const ResultBadge = ({ trait, score, percentile }) => (
  <div className="flex flex-col items-center justify-center bg-soft-gray rounded-lg p-4 shadow-sm">
    <div className="relative w-24 h-24 mb-2"> {/* Increased size and added margin-bottom */}
      <svg className="w-full h-full" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet">
        <circle cx="18" cy="18" r="15.9155" fill="none" stroke="#F0F4F8" strokeWidth="3" />
        <circle 
          cx="18" 
          cy="18" 
          r="15.9155" 
          fill="none" 
          stroke="#0077BE" 
          strokeWidth="3"
          strokeDasharray={`${score}, 100`}
          transform="rotate(-90 18 18)"
        />
        <text x="18" y="22" className="fill-current text-cerulean-blue font-bold text-5xl" textAnchor="middle" dominantBaseline="central">
          {score}
        </text>
      </svg>
    </div>
    <span className="font-semibold text-deep-navy text-center">{trait}</span>
    <span className="text-sm text-charcoal">{percentile}%ile</span>
  </div>
);

export const Tooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="cursor-help"
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 w-64 px-4 py-2 text-sm text-white bg-charcoal rounded-lg shadow-lg bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2">
          {content}
        </div>
      )}
    </div>
  );
};

export const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-soft-gray">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full py-4 px-6 text-left font-semibold focus:outline-none focus:ring-2 focus:ring-cerulean-blue"
      >
        {title}
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isOpen && <div className="px-6 py-4">{children}</div>}
    </div>
  );
};

export const Sparkline = ({ data, width = 100, height = 30, color = "#0077BE" }) => {
  const values = data.map(d => d.value);
  const min = Math.min(...values);
  const max = Math.max(...values);
  const range = max - min;

  const points = data.map((d, i) => {
    const x = (i / (data.length - 1)) * width;
    const y = height - ((d.value - min) / range) * height;
    return `${x},${y}`;
  }).join(' ');

  return (
    <svg width={width} height={height}>
      <polyline
        fill="none"
        stroke={color}
        strokeWidth="2"
        points={points}
      />
    </svg>
  );
};

export const InfoIcon = ({ tooltipContent }) => (
  <Tooltip content={tooltipContent}>
    <HelpCircle size={16} className="inline-block ml-1 text-cerulean-blue" />
  </Tooltip>
);
