// DISCAssessment.js

import React, { useState } from 'react';
import AssessmentLoader from './AssessmentLoader';
import DISCResultsComponent from './DISCResults';
import styles from '../styles/Utilities/Assessment.module.scss';
import SEO from './SEO';

const DISCAssessment = () => {
  const [showResults, setShowResults] = useState(false);
  const [scores, setScores] = useState(null);
  const [questions, setQuestions] = useState([]);

  const handleComplete = (completedResponses) => {
    const calculatedScores = calculateScores(completedResponses, questions);
    setScores(calculatedScores);
    setShowResults(true);
  };

  const calculateScores = (responses, questions) => {
    const factors = { D: 0, I: 0, S: 0, C: 0 };
    
    questions.forEach((question, index) => {
      const response = responses[index];
      factors[question.factor] += response;
    });

    // Calculate percentages
    const total = Object.values(factors).reduce((sum, score) => sum + score, 0);
    const percentages = {};
    for (const factor in factors) {
      percentages[factor] = Math.round((factors[factor] / total) * 100);
    }

    // Determine primary and secondary styles
    const sortedFactors = Object.entries(percentages).sort((a, b) => b[1] - a[1]);
    const primaryStyle = sortedFactors[0][0];
    const secondaryStyle = sortedFactors[1][0];

    return { factors, percentages, primaryStyle, secondaryStyle };
  };

  const handleQuestionsLoaded = (loadedQuestions) => {
    setQuestions(loadedQuestions);
  };

  return (
    <div className={styles.assessment}>
                       <SEO 
        title="DISC Assessment | Psychometrics.io"
        description="Discover your true self with scientifically backed personality assessments for personal and professional growth."
        keywords="psychometrics, personality tests, Big Five, DISC, Jungian Type, Free Assessment"
        ogImage="https://psychometrics.io/static/media/psychometrics-logo.png"
      />
      <h1 className={styles.assessmentTitle}>DISC Assessment</h1>
      {!showResults ? (
        <AssessmentLoader
          assessmentId="disc"
          onComplete={handleComplete}
          onQuestionsLoaded={handleQuestionsLoaded}
        />
      ) : (
        <DISCResultsComponent scores={scores} />
      )}
    </div>
  );
};

export default DISCAssessment;
