import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../images/logo.svg';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header__container">
        <Link to="/" className="header__logo">
          <img src={logo} alt="Psychometrics.io Logo" />
        </Link>
        <button className="header__menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? 'Close' : 'Menu'}
        </button>
        <nav className={`header__nav ${isMenuOpen ? 'is-active' : ''}`}>
          <ul className="header__links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/assessments">Assessments</Link></li>
            <li><Link to="/blog">Blog</Link></li>

          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
