import React, { useState, useEffect } from 'react';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import styles from '../styles/Utilities/Assessment.module.scss';

const AssessmentLoader = ({ assessmentId, onComplete, onQuestionsLoaded }) => {
  const [assessmentData, setAssessmentData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    const loadAssessment = async () => {
      try {
        const module = await import(`../assessments/${assessmentId}.json`);
        const data = module.default;
        setAssessmentData(data);
        setResponses(new Array(data.questions.length).fill(null));
        if (onQuestionsLoaded) {
          onQuestionsLoaded(data.questions);
        }
      } catch (error) {
        console.error('Error loading assessment:', error);
      }
    };
    loadAssessment();
  }, [assessmentId, onQuestionsLoaded]);

  const handleAnswer = (value) => {
    if (currentQuestion >= 0) {
      const newResponses = [...responses];
      newResponses[currentQuestion] = value;
      setResponses(newResponses);
    }
    if (currentQuestion < assessmentData.questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else if (currentQuestion === assessmentData.questions.length - 1) {
      onComplete(responses);
    }
  };

  const handleBack = () => {
    if (currentQuestion > -1) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  if (!assessmentData) {
    return <div className={styles.assessment}>Loading assessment...</div>;
  }

  const progress = ((currentQuestion + 2) / (assessmentData.questions.length + 1)) * 100;

  const renderIntroduction = () => (
    <div className={styles.questionCard}>
      <h2 className={styles.assessmentTitle}>{assessmentData.introduction.title}</h2>
      <div className={styles.questionText}>
        <p>{assessmentData.introduction.content}</p>
        <p dangerouslySetInnerHTML={{ __html: assessmentData.introduction.validityStatement }} className={styles.validityStatement} />
        <p className={styles.instructions}>{assessmentData.introduction.instructions}</p>
      </div>
      <div className={styles.buttonContainer}>
        <button onClick={() => handleAnswer(null)} className={`${styles.btn} ${styles.btnPrimary}`}>
          Next <ArrowRight className={styles.ml2} />
        </button>
      </div>
    </div>
  );

  const renderQuestion = () => {
    const question = assessmentData.questions[currentQuestion];
    return (
      <div className={styles.questionCard}>
        <h3 className={styles.questionText}>{question.text}</h3>
        <div>
          {assessmentData.options.map((option) => (
            <button
              key={option.value}
              onClick={() => handleAnswer(option.value)}
              className={`${styles.optionButton} ${responses[currentQuestion] === option.value ? styles.selected : ''}`}
            >
              {option.label}
            </button>
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <button 
            onClick={handleBack} 
            className={`${styles.btn} ${styles.btnSecondary}`}
            disabled={currentQuestion === 0}
          >
            <ArrowLeft className={styles.mr2} />
            Back
          </button>
          {currentQuestion < assessmentData.questions.length - 1 && (
            <button 
              onClick={() => handleAnswer(responses[currentQuestion])} 
              className={`${styles.btn} ${styles.btnPrimary}`}
              disabled={responses[currentQuestion] === null}
            >
              Next <ArrowRight className={styles.ml2} />
            </button>
          )}
          {currentQuestion === assessmentData.questions.length - 1 && (
            <button 
              onClick={() => onComplete(responses)} 
              className={`${styles.btn} ${styles.btnPrimary}`}
              disabled={responses[currentQuestion] === null}
            >
              Complete
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.assessment}>
      <h1 className={styles.assessmentTitle}>{assessmentData.name}</h1>
      <p className={styles.progressText}>
        Question {currentQuestion + 2} of {assessmentData.questions.length + 1}
      </p>
      <div className={styles.progressBar}>
        <div 
          className={styles.progressFill}
          style={{ width: `${progress}%` }}
        />
      </div>
      {currentQuestion === -1 ? renderIntroduction() : renderQuestion()}
    </div>
  );
};

export default AssessmentLoader;
