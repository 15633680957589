import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, CardContent, InfoIcon, Button } from './ui';
import styles from '../styles/Utilities/Results.module.scss';
import { motion } from 'framer-motion';
import {jStat} from 'jstat';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';


const dichotomyNames = {
  E: "Extraversion",
  I: "Introversion",
  S: "Sensing",
  N: "Intuition",
  T: "Thinking",
  F: "Feeling",
  J: "Judging",
  P: "Perceiving"
};

const dichotomyDescriptions = {
  E: "Focuses on the outer world of people and activities.",
  I: "Focuses on the inner world of ideas and impressions.",
  S: "Focuses on the present and concrete information from their senses.",
  N: "Focuses on the future, with a view toward patterns and possibilities.",
  T: "Makes decisions based primarily on logic and on objective analysis.",
  F: "Makes decisions based primarily on values and on subjective evaluation of person-centered concerns.",
  J: "Prefers a planned and organized approach to life and likes to have things settled.",
  P: "Prefers a flexible and spontaneous approach to life and likes to keep options open."
};

const calculatePercentile = (score) => {
  const mean = 20;
  const sd = 5;
  return Math.round(jStat.normal.cdf(score, mean, sd) * 100);
};

const generateTypeDescription = (type) => {
  const descriptions = {
    ISTJ: "Quiet, serious, earn success by thoroughness and dependability. Practical, matter-of-fact, realistic, and responsible.",
    ISFJ: "Quiet, friendly, responsible, and conscientious. Committed and steady in meeting their obligations.",
    INFJ: "Seek meaning and connection in ideas, relationships, and material possessions. Want to understand what motivates people.",
    INTJ: "Have original minds and great drive for implementing their ideas and achieving their goals.",
    ISTP: "Tolerant and flexible, quiet observers until a problem appears, then act quickly to find workable solutions.",
    ISFP: "Quiet, friendly, sensitive, and kind. Enjoy the present moment, what's going on around them. Like to have their own space.",
    INFP: "Idealistic, loyal to their values and to people who are important to them. Want an external life that is congruent with their values.",
    INTP: "Seek to develop logical explanations for everything that interests them. Theoretical and abstract, interested more in ideas than in social interaction.",
    ESTP: "Flexible and tolerant, they take a pragmatic approach focused on immediate results. Bored by theories, they want to act energetically to solve the problem.",
    ESFP: "Outgoing, friendly, and accepting. Exuberant lovers of life, people, and material comforts. Enjoy working with others to make things happen.",
    ENFP: "Warmly enthusiastic and imaginative. See life as full of possibilities. Make connections between events and information very quickly.",
    ENTP: "Quick, ingenious, stimulating, alert, and outspoken. Resourceful in solving new and challenging problems.",
    ESTJ: "Practical, realistic, matter-of-fact. Decisive, quickly move to implement decisions. Organize projects and people to get things done.",
    ESFJ: "Warmhearted, conscientious, and cooperative. Want harmony in their environment, work with determination to establish it.",
    ENFJ: "Warm, empathetic, responsive, and responsible. Highly attuned to the emotions, needs, and motivations of others.",
    ENTJ: "Frank, decisive, assume leadership readily. Quickly see illogical and inefficient procedures and policies, develop and implement comprehensive systems to solve organizational problems."
  };
  return descriptions[type] || "Description not available.";
};

const writeResponsesToJson = (scores) => {
  const jsonData = JSON.stringify(scores, null, 2);
  return new Blob([jsonData], { type: 'application/json' });
};

const uploadToS3 = async (file, identifier) => {
  console.log('Starting S3 upload...');
  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
  });

  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Key: `jungian-type-results/${identifier}-${Date.now()}.json`,
    Body: file,
    ContentType: 'application/json',
  };


  try {
    const command = new PutObjectCommand(params);
    await s3Client.send(command);
    console.log('Successful');
  } catch (error) {
    console.error('Error');
  }
};

const JungianTypeResultsComponent = ({ scores, userId }) => {
  const [sessionId, setSessionId] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');


  useEffect(() => {
    // Check if a sessionId already exists in localStorage
    let storedSessionId = localStorage.getItem('assessmentSessionId');
    
    if (!storedSessionId) {
      // If no sessionId exists, generate a new one and store it
      storedSessionId = uuidv4();
      localStorage.setItem('assessmentSessionId', storedSessionId);
    }
    
    setSessionId(storedSessionId);
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const effectiveId = userId || sessionId;
    console.log('Attempting upload with ID:', effectiveId);
    
    if (scores && effectiveId) {
      const jsonFile = writeResponsesToJson(scores);
      uploadToS3(jsonFile, effectiveId);
    }
  }, [scores, userId, sessionId]);

  if (!scores) {
    return <div className={styles.results__loading}>Analyzing your Jungian type...</div>;
  }

  const { dichotomies, type } = scores;

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    series: [{
      data: Object.values(dichotomies)
    }],
    xaxis: {
      categories: Object.keys(dichotomies).map(key => dichotomyNames[key]),
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val + "%";
        }
      }
    },
    title: {
      text: 'Jungian Type Preferences',
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  };
  const renderOverviewTab = () => (
    <>
      <Card className={styles.results__interpretation_item}>
        <CardHeader>Your Jungian Type: {type}</CardHeader>
        <CardContent>
          <p className={styles.results__interpretation_text}>{generateTypeDescription(type)}</p>
        </CardContent>
      </Card>
      <Card className={styles.results__chart}>
        <CardHeader>Dichotomy Comparison</CardHeader>
        <CardContent>
          <ReactApexChart options={barChartOptions} series={barChartOptions.series} type="bar" height={350} />
        </CardContent>
      </Card>
    </>
  );

  const renderDetailedResultsTab = () => (
    <>
      {Object.entries(dichotomies).map(([dichotomy, score]) => (
        <motion.div
          key={dichotomy}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card className={styles.results__interpretation_item}>
            <CardHeader>
              {dichotomyNames[dichotomy]}
            </CardHeader>
            <CardContent>
              <div className={styles.results__progress}>
                <div className={styles.results__progress_bar}>
                  <div 
                    className={styles.results__progress_fill} 
                    style={{ width: `${(score / 40) * 100}%` }}
                  />
                  <span className={styles.results__progress_label}>{score}</span>
                </div>
                <div className={styles.results__progress_labels}>
                  <span>{dichotomy === 'E' ? 'I' : dichotomy === 'S' ? 'N' : dichotomy === 'T' ? 'F' : 'P'}</span>
                  <span>Balanced</span>
                  <span>{dichotomy}</span>
                </div>
              </div>
              <p className={styles.results__interpretation_text}>
                {dichotomyDescriptions[dichotomy]}
              </p>
            </CardContent>
          </Card>
        </motion.div>
      ))}
    </>
  );

  const renderApplicationsTab = () => (
    <Card className={styles.results__interpretation_item}>
      <CardHeader>Understanding Your Jungian Type</CardHeader>
      <CardContent>
        <p className={styles.results__interpretation_text}>
          Your Jungian Type, {type}, is a combination of four preferences that indicate how you tend to:
        </p>
        <ul className={styles.results__list}>
          <li>Focus your attention ({type[0] === 'E' ? 'Extraversion' : 'Introversion'})</li>
          <li>Take in information ({type[1] === 'S' ? 'Sensing' : 'Intuition'})</li>
          <li>Make decisions ({type[2] === 'T' ? 'Thinking' : 'Feeling'})</li>
          <li>Approach the outside world ({type[3] === 'J' ? 'Judging' : 'Perceiving'})</li>
        </ul>
        <p className={styles.results__interpretation_text}>
          Remember that everyone uses all eight preferences at different times, but your type suggests your natural inclinations. This understanding can help you:
        </p>
        <ul className={styles.results__list}>
          <li>Leverage your strengths in personal and professional settings</li>
          <li>Recognize areas for potential growth and development</li>
          <li>Improve communication and relationships with others</li>
          <li>Make informed decisions about career paths and personal development goals</li>
        </ul>
        <p className={styles.results__interpretation_text}>
          Use this insight as a starting point for self-reflection and personal growth, rather than a definitive label or limitation.
        </p>
      </CardContent>
    </Card>
  );

  return (
    <div className={styles.results}>
      <div className={styles.results__header}>
        <h1 className={styles.results__title}>Your Jungian Type Profile</h1>
      </div>

      <div className={styles.results__tabs}>
        <Button 
          onClick={() => setActiveTab('overview')} 
          variant={activeTab === 'overview' ? 'primary' : 'secondary'}
        >
          Overview
        </Button>
        <Button 
          onClick={() => setActiveTab('detailed')} 
          variant={activeTab === 'detailed' ? 'primary' : 'secondary'}
        >
          Detailed Results
        </Button>
        <Button 
          onClick={() => setActiveTab('applications')} 
          variant={activeTab === 'applications' ? 'primary' : 'secondary'}
        >
          Applications
        </Button>
      </div>

      <div className={styles.results__content}>
        {activeTab === 'overview' && renderOverviewTab()}
        {activeTab === 'detailed' && renderDetailedResultsTab()}
        {activeTab === 'applications' && renderApplicationsTab()}
      </div>
    </div>
  );
};

export default JungianTypeResultsComponent;